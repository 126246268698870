import { cn } from '@lemonenergy/lemonpie-3'
import { ChevronDown } from '@lemonenergy/lemonpie-icons'
import type { HTMLAttributes } from 'react'
import { forwardRef, useState } from 'react'

// How it works?
// To animate the height of the content we use CSS grid template rows variations
// It's a CSS grid with a single grid item. To open, just transition grid-template-rows from 0fr to 1fr, so the grid item transitions to its auto height.
// The one caveat is the needed of `div` wrapping the content's children.
// See more:
// - https://chriscoyier.net/2022/12/21/things-css-could-still-use-heading-into-2023/#animate-to-auto
// - https://keithjgrant.com/posts/2023/04/transitioning-to-height-auto/
// Also, we use the `data-state` attribute to manage the state of the accordion
// Inside the <details> we use group/accordion, to check the data-state
// The Content uses peer/accordion to check the data-state

export const AccordionItem = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ children, ...props }, forwardedRef) => {
  return (
    <div {...props} ref={forwardedRef}>
      {children}
    </div>
  )
})

AccordionItem.displayName = 'AccordionItem'

export const AccordionHeader = forwardRef<
  HTMLDetailsElement,
  React.DetailedHTMLProps<
    React.DetailsHTMLAttributes<HTMLDetailsElement>,
    HTMLDetailsElement
  >
>(({ children, className, open, onToggle, ...props }, forwardedRef) => {
  const [opened, setOpened] = useState(open)

  return (
    <details
      className={cn(
        'peer/accordion group/accordion overflow-hidden',
        className,
      )}
      ref={forwardedRef}
      open={opened}
      data-state={opened ? 'open' : 'closed'}
      onToggle={e => {
        setOpened(e.currentTarget?.open)
        onToggle?.(e)
      }}
      {...props}
    >
      {children}
    </details>
  )
})

AccordionHeader.displayName = 'AccordionHeader'

export const AccordionTrigger = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <summary
      className={cn(
        'cursor-pointer [&::-webkit-details-marker]:hidden',
        className,
      )}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </summary>
  )
})

AccordionTrigger.displayName = 'AccordionTrigger'

export const AccordionIcon = ({
  className,
  ...props
}: Parameters<typeof ChevronDown>[0]) => (
  <ChevronDown
    className={cn(
      'group-data-[state=open]/accordion:rotate-180 transition-transform duration-200',
      className,
    )}
    {...props}
  />
)

AccordionIcon.displayName = 'AccordionIcon'

export const AccordionContent = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <div
      className={cn(
        'peer-data-[state=open]/accordion:grid-rows-[1fr]',
        'grid grid-rows-[0fr] transition-[grid-template-rows] ease-out duration-200',
        className,
      )}
      {...props}
      ref={forwardedRef}
    >
      <div className="overflow-hidden">{children}</div>
    </div>
  )
})

AccordionContent.displayName = 'AccordionContent'

export const AccordionGroup = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <div
      className={cn('flex flex-col', className)}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </div>
  )
})

AccordionGroup.displayName = 'AccordionGroup'
